/* eslint-disable no-console */
<template>
  <div>
    <v-toolbar
      flat
    >
      <v-btn
        class="mx-4"
        small
        text
        @click="navigateToList"
      >
        <v-icon class="mx-2">mdi-view-headline</v-icon>
        {{ crudLabels.list }}
      </v-btn>

      <v-btn
        class="mx-4"
        color="primary"
        small
        text
        @click="navigateToFormInsert"
      >
        <v-icon  class="mx-2">mdi-playlist-plus</v-icon>
        {{ crudLabels.create }}
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <!-- Editable form: begin -->
          <v-form ref="form" v-model="isFormContentValid" dense>
            <!-- Main Section Fields -->

            <v-row>
              <v-col cols="12" sm="12" md="12" dense >
                <v-select
                  v-model="selectedUserId"
                  :items="refUserListInSelect"
                  :label="$tc('user.label', 1)"
                  item-text="text"
                  item-value="value"
                  dense
                  clearable
                  clear-icon="mdi-eraser"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12" dense >
                <v-select
                  v-model="selectedBadgeId"
                  :items="refBadgeListInSelect"
                  :label="$tc('badge.label', 1)"
                  item-text="text"
                  item-value="value"
                  dense
                  clearable
                  clear-icon="mdi-eraser"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
          <!-- Editable form: end -->
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!isNewItem && crudAccess.delete"
          color="orange darken-1"
          text
          @click="displayDialogDelete"
        >
          {{ crudLabels.delete }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          {{ $t("appButton.cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
          :disabled="!isFormContentValid"
        >
          {{ $t("appButton.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="isDisplayedDialogDelete" max-width="800px">
      <v-card>
        <v-card-title class="stong">{{ crudLabels.confirmDelete }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialogDelete"
            >{{ $t("appButton.cancel") }}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDeleteItem"
            >{{ $t("appButton.delete") }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {
  // datatypes,
  // baseDatatypes,
  // reservedDatatypes,
  // getBooleanLabel,
  getTemplateFieldType,
} from '@/model/datatypes';
import { mapGetters } from 'vuex';
import { UNDEFINED_STOPTS } from '@/model/genericValues';
import { urlFormatter } from '@/router/index';
import { badgeUser } from '@/model/badgeuser';
import dbAPI from '../api/badgesusers.api';

import badgesAPI from '../api/badges.api';
import usersAPI from '../api/users.api';
import i18n from '../i18n';
import { defaultCrudAccess, getAllowedCrudForUser } from '../model/accessRolesModel';

export const DEFAULT_VALUES = {
  editedId: '',
  newItem: {
    badgeId: '',
    userId: '',
  },
};

export default {
  name: 'BadgeUserForm',
  components: {
  },
  data: () => ({
    tablename: 'badgeuser',
    crudAccess: defaultCrudAccess,
    crudLabels: {},
    editedId: DEFAULT_VALUES.editedId,
    editedItem: {},
    isDisplayedDialogDelete: false,
    isFormContentValid: false,
    loading: true,
    model: [],
    record: {},
    records: [],
    refBadgeList: [],
    refUserList: [],
    selectedUserId: [],
    selectedBadgeId: [],
    runningStatus: '',
    tableHeaders: [],
  }),

  created() {
    this.crudAccess = getAllowedCrudForUser(badgeUser.crudAllowedRoles, this.userRoles);
    if (!this.crudAccess.read && !this.crudAccess.create && !this.crudAccess.update) {
      const msg = this.$t('errMsg.forbiddenAccess_fmt',
        { operation: this.$t('crudLabels.read_write').toLowerCase() });
      this.$store.dispatch('displayAlert', msg);
      this.$router.go(-1);
    }
    this.refreshData();
  },

  watch: {
    $route() {
      this.refreshData();
    },
  },

  computed: {
    ...mapGetters([
      'userRoles',
    ]),

    formTitle() {
      return this.isNewItem ? this.crudLabels.create : this.crudLabels.update;
    },

    isNewItem() {
      return this.editedId === DEFAULT_VALUES.editedId;
    },

    refBadgeListInSelect() {
      return this.refBadgeList.map((rec) => ({
        value: rec.id,
        text: `${rec.macAddress}  - UUID: ${rec.uuid} - RFID tag ID: ${rec.rfidTagId})`,
      })).sort((a, b) => (b.text.toLowerCase() < a.text.toLowerCase()));
    },

    refUserListInSelect() {
      return this.refUserList.map((rec) => ({
        value: rec.id,
        text: `${rec.lastname.toUpperCase()} ${rec.firstname} (${rec.companyName})`,
      })).sort((a, b) => (b.text.toLowerCase() < a.text.toLowerCase()));
    },

    selectedUser() {
      if (!this.selectedUserId) return null;
      return this.refUserList.find((usr) => usr.id === this.selectedUserId);
    },

    selectedBadge() {
      if (!this.selectedBadgeId) return null;
      return this.refBadgeList.find((sta) => sta.id === this.selectedBadgeId);
    },
  },

  methods: {
    confirmDeleteItem() {
      this.$store.dispatch('displayAlert', 'Not implemented : badgesUser.confirmDeleteItem().');
      this.closeDialogDelete();
    },

    close() {
      this.$router.push(urlFormatter.badgeUserList);
    },

    closeDialogDelete() {
      this.isDisplayedDialogDelete = false;
    },

    createRecordThenClose() {
      let record = {};
      let created = false;
      /* eslint-disable no-param-reassign */
      record = this.validateRecordBeforeWriteAPICall(record);
      /* eslint-enable no-param-reassign */
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
        { operation: this.$t('crudLabels.creating') }));
      this.$nextTick(() => {
        dbAPI.create(record)
          .then((data) => {
            created = true;
            if (data.status === 201) {
              this.records.push(data.data);
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.created') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.creation').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loading = false;
            if (created) this.close();
          });
      });
    },

    deleteRecord(record) {
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
        { operation: this.$t('crudLabels.deleting') }));
      this.$nextTick(() => {
        dbAPI.delete(record)
          .then((data) => {
            if (data.status === 204) {
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.deleted') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.deleting').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loading = false;
          });
      });
    },

    displayDialogDelete() {
      this.isDisplayedDialogDelete = true;
    },

    getRefBadgeList() {
      badgesAPI.getList().then((response) => {
        this.refBadgeList = response.data.data;
      }).catch((error) => {
        const msg = this.$tc(`${'badge'}.abortedMsg_Fmt`, 2,
          { operation: this.$t('crudLabels.read').toLowerCase() });
        const apiErrorMsg = error?.response?.data?.error_msg || '';
        console.error(`Error : ${msg}`, error, apiErrorMsg);
        this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
      });
    },

    getRefUserList() {
      this.loading = true;
      this.$nextTick(() => {
        usersAPI.getList().then((response) => {
          this.refUserList = response.data.data;
        }).catch((error) => {
          const msg = this.$tc(`${'user'}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    getList() {
      this.loading = true;
      this.$nextTick(() => {
        this.getRefBadgeList();
        this.getRefUserList();
        dbAPI.getList().then((response) => {
          this.records = response.data.data;
          console.log(this.records);
        }).catch((error) => {
          const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    isItemUpdatable() {
      return this.crudAccess.update;
    },

    navigateToFormInsert() {
      if (this.$refs?.form) this.$refs.form.resetValidation();
      this.$router.push(urlFormatter.badgeUserFormNew);
    },

    navigateToList() {
      if (this.$refs?.form) this.$refs.form.resetValidation();
      this.$router.push(urlFormatter.badgeUserList);
    },

    refreshData() {
      try {
        this.crudAccess = getAllowedCrudForUser(badgeUser.crudAllowedRoles, this.userRoles);
        this.editedId = this.$route.params.id ?? DEFAULT_VALUES.editedId;
        this.setCrudLabels();
        // Default Empty Item
        this.editedItem = { ...DEFAULT_VALUES.newItem };
        // this.editedItem = this.localizeDataValues(this.editedItem);
        this.getList();
      } catch (error) {
        this.$store.dispatch('displayAlert', error.message);
        throw new Error(this.$t('errMsg.validationError'), error);
      } finally {
        this.loading = false;
      }
    },

    save() {
      if (this.isNewItem) {
        this.createRecordThenClose();
      } else {
        this.updateRecordThenClose();
      }
    },

    setCrudLabels() {
      this.crudLabels = {
        create: i18n.t(`${this.tablename}.c`),
        retrieve: i18n.tc(`${this.tablename}.r`, 1),
        update: i18n.tc(`${this.tablename}.u`, 1),
        delete: i18n.tc(`${this.tablename}.d`, 1),
        list: i18n.tc(`${this.tablename}.list`, 1),
        confirmDelete: i18n.tc(`${this.tablename}.confirmD`, 1),
      };
    },

    templateFieldType(field) {
      return getTemplateFieldType(field.type);
    },

    updateRecordThenClose() {
      throw Error('updateRecordThenClose : method copied but not tested ! Do not use like this !');
      // let editedRecord = {};
      // const complete = this.validateRecordBeforeWriteAPICall(editedRecord);
      // /* eslint-disable no-param-reassign */
      // if (complete) editedRecord = complete;
      // else return;
      // /* eslint-enable no-param-reassign */
      // this.loading = true;
      // this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
      //   { operation: this.$t('crudLabels.updating') }));
      // this.$nextTick(() => {
      //   dbAPI.update(editedRecord.id, editedRecord)
      //     .then((data) => {
      //       if (data.status === 201) {
      //         const localIndex = this.records.findIndex((item) => (item.id === editedRecord.id));
      //         this.records.splice(localIndex, 1, data.data);
      //         this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
      //           { operation: this.$t('crudLabels.updated') }));
      //       }
      //     })
      //     .catch((error) => {
      //       const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
      //         { operation: this.$t('crudLabels.update').toLowerCase() });
      //       const apiErrorMsg = error?.response?.data?.error_msg || '';
      //       console.error(`Error : ${msg}`, error, apiErrorMsg);
      //       this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
      //     })
      //     .then(
      //       this.loading = false,
      //       this.close(),
      //     );
      // });
    },

    validateRecordBeforeWriteAPICall(record) {
      let result = null;
      try {
        const bad = this.selectedBadge;
        if (!bad) throw ReferenceError('Unable to retrieve selected Badge !');
        const usr = this.selectedUser;
        if (!usr) throw ReferenceError('Unable to retrieve selected User !');
        /* eslint-disable no-param-reassign */
        const startTs = Math.round(new Date().getTime() / 1000);
        record.pK = bad.id;
        record.sK = `${usr.id}:${startTs}`;
        record.startTs = startTs;
        record.stopTs = UNDEFINED_STOPTS;
        record.userId = usr.id;
        record.userIsActive = usr.userIsActive; // TODO : load this from Form !!!!
        record.email = usr.email;
        record.firstname = usr.firstname;
        record.lastname = usr.lastname;
        record.macAddress = bad.macAddress;
        record.uuid = bad.uuid;
        record.rfidTagId = bad.rfidTagId;
        record.badgeIsActive = bad.badgeIsActive;
        record.companyId = usr.companyId;
        record.companyName = usr.companyName;
        record.companyIsActive = usr.companyIsActive;
        /* eslint-enable no-param-reassign */
        result = record;
      } catch (error) {
        const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
          { operation: this.$t('crudLabels.creation').toLowerCase() });
        this.$store.dispatch('displayAlert', `${msg}: ${error}.`);
      }
      return result;
    },

  },
};
</script>
